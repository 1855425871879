import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  max-width: 700px;
  margin: 20px auto 40px;
  text-align: center;
  font-size: 20px;
  line-height: 1.5rem;
`

function TextMessage({ children }) {

  return (
    <Container>
      {children}
    </Container>
  )
}

export default TextMessage
