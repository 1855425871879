import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubSection from "../components/subSection"
import Section from "../components/section"
import ContactSection from "../components/contactSection"
import TextMessage from "../components/textMessage"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const UsemeLogoLink = styled.a`
  display: block;
  margin-bottom: 8px;
`

const IndexPage = () => (
  <Layout>
    <TextMessage>
      Witaj na stronie współpracy z portalem REDARK.pl! Znajdziesz tutaj wszelkie potrzebne informacje do nawiązania ze mną współpracy. W razie dodatkowych pytań, skorzystaj z adresu e-mail widniejącego w sekcji "Kontakt".
    </TextMessage>
    <Seo title="Współpraca" />
    <Section title={'Krok po kroku'}>
      <SubSection title={'Moja oferta'}
                  description={'Zapoznaj się z treścią oferty zawartej w pliku PDF dostępnym w sekcji "Dokumenty" znajdującej się poniżej.'}
                  iconType={1} />
      <SubSection title={'Kontakt mailowy'}
                  description={'W przypadku chęci nawiązania współpracy, zadania dodatkowych pytań lub zaproponowania nawiązania niestandardowej współpracy napisz do mnie na podany na dole adres e-mail.'}
                  iconType={2} />
      <SubSection title={'Uzgadnianie warunków współpracy'}
                  description={'W drodze konwersacji mailowej uzgodnimy, co będzie przedmiotem współpracy, kwestie wynagrodzenia oraz doprecyzujemy warunki umowy. Przejrzyj proszę dokumenty dostępne na tej stronie - możesz zaproponować modyfikację (doprecyzowanie) niektórych jej zapisów umowy.'}
                  iconType={3} />
      <SubSection title={'Podpisanie umowy i realizacja'}
                  description={'Na podstawie zebranych podczas rozmowy danych przygotuję "Umowę o Współpracy", która zostanie podpisana przez obie strony. Po jej podpisaniu rozpocznę realizację postanowień umowy.'}
                  iconType={4} />
      <SubSection title={'Wypłata wynagrodzenia'}
                  description={'Po publikacji treści reklamowych, zgodnie z zapisami umowy za pośrednictwem firmy Useme, wystawię fakturę pro forma, po zapłacie której pieniądze automatycznie trafią do mnie.'}
                  iconType={5} />
    </Section>
    <Section title={'Dokumenty'}>
      <SubSection title={'Oferta współpracy'}
                  description={'o portalu, o mnie, preferowane formy współpracy, statystyki ruchu, cennik'}
                  iconType={'PDF'}
                  fileName={'Oferta_REDARKpl.pdf'} />
      <SubSection title={'Regulamin Emisji Treści Reklamowych'}
                  description={'ogólne zasady publikowania treści reklamowych'}
                  iconType={'PDF'}
                  fileName={'RETR_REDARKpl.pdf'} />
      <SubSection title={'Specyfikacja Techniczna Treści Reklamowych'}
                  description={'opis formy i parametrów meteriałów wymaganych do publikacji artykułów'}
                  iconType={'PDF'}
                  fileName={'STTR_REDARKpl.pdf'} />
    </Section>
    <Section title={'Zaufane płatności'}>
      <TextMessage>
        <UsemeLogoLink href={'https://useme.com/pl/'} target={'_blank'} rel={'noreferrer'}>
          <StaticImage
            src="../images/useme-logo.jpg"
            quality={90}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Useme logo" imgStyle={{ borderRadius: '10px' }} />
        </UsemeLogoLink>
        Płatność za usługę odbywa się za pośrednictwem firmy Useme, która gwarantuje bezpieczeństwo transakcji płatniczych pomiędzy Reklamodawcami a Freelancerami. Dzięki nim nie muszę martwić się księgowością oraz mogę wystawić fakturę VAT jako osoba fizyczna :)
      </TextMessage>
    </Section>
    <Section title={'Kontakt'}>
      <ContactSection/>
    </Section>
  </Layout>
)

export default IndexPage
