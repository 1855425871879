import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Btn = styled.a`
  display: inline-block;
  margin: 5px auto 30px;
  padding: 5px 10px !important;
  background-color: #d40000;
  border: 3px #d40000 solid !important;
  border-radius: 10px;
  text-decoration: none;
  min-width: 160px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
  color: #fff !important;
  transition: .3s !important;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #d40000 !important;
    background-position: 0 0 !important;
  }
`

function ActionButton({ onClick, externalLink, style, children }) {

  const onButtonClick = () => {
    onClick()
    return false
  }

  if(onClick) {
    return (
      <Btn href={externalLink} onClick={onButtonClick} style={style}>
        {children}
      </Btn>
    )
  }else{
    return (
      <Btn href={externalLink} target={'_blank'} rel="noreferrer" style={style}>
        {children}
      </Btn>
    )
  }
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
  externalLink: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.string.isRequired,
}

export default ActionButton
