import * as React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import ActionButton from "./actionButton"

const Container = styled.div`
  max-width: 800px;
  min-height: 130px;
  margin: 20px auto;
  text-align: center;
`

const EmailAddress = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 30px;
  margin: 30px auto;
  
  &:hover {
    border-bottom: 2px #d40000 solid;
  }
`

const SocialIconContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

const SocialIcon = styled(FontAwesomeIcon)`
  color: #fff;
  font-size: 56px;
  margin: 0 10px;
  transition: .4s;
  
  &:hover {
    color: ${props => props.hoverColor};
  }
`

const config = {
  email: 'redarkpl@gmail.com',
  social: [
    { icon: faFacebookSquare, hoverColor: '#3b5999', name: 'Facebook', link: 'https://www.facebook.com/redarkpl' },
    { icon: faTwitterSquare, hoverColor: '#55acee', name: 'Twitter', link: 'https://twitter.com/REDARKpl' },
    { icon: faInstagram, hoverColor: '#e4405f', name: 'Instagram', link: 'https://www.instagram.com/redark.pl/' },
  ]
}

function ContactSection() {
  const [hidden, setHidden] = React.useState(true)

  if(hidden) {
    return (
      <Container>
        <ActionButton onClick={() => setHidden(false)}>
          Odsłoń dane kontaktowe
        </ActionButton>
      </Container>
    )
  }else{
    return (
      <Container>
        <SocialIconContainer>
          <h2>Adres e-mail:</h2>
          <EmailAddress href={'mailto:'+config.email}>{config.email}</EmailAddress>
          <h2>Social media:</h2>
          {
            config.social.map(icon => (
              <a key={icon.name} href={icon.link} target={'_blank'} rel="noreferrer">
                <SocialIcon key={icon.name} icon={icon.icon} hoverColor={icon.hoverColor} />
              </a>
            ))
          }
        </SocialIconContainer>
        <ActionButton externalLink={'https://redark.pl'}>
          Przejdź do portalu REDARK.pl
        </ActionButton>
      </Container>
    )
  }
}

export default ContactSection
