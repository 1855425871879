import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ActionButton from "./actionButton"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'

const Container = styled.div`
  max-width: 800px;
  min-height: 130px;
  margin: 0 auto 20px;
`

const BigIcon = styled(FontAwesomeIcon)`
  font-size: 80px;
  position: absolute;
`

const BigNumber = styled.div`
  font-size: 80px;
  position: absolute;
`

const Title = styled.h2`
  margin-left: 90px;
  font-size: 24px;
  margin-bottom: 5px;
`

const Desciption = styled.p`
  margin-left: 90px;
  font-size: 16px;
  line-height: 1.5rem;
`

function SubSection({ title, description, iconType, fileName }) {

  return (
    <Container>
      {iconType && isNaN(iconType) && <BigIcon icon={faFilePdf} />}
      {iconType && !isNaN(iconType) && <BigNumber>{iconType}</BigNumber>}
      <Title>{title}</Title>
      <Desciption>{description}</Desciption>
      {
        fileName
        &&
        <ActionButton externalLink={fileName} style={{ float: 'right' }}>
          Otwórz plik PDF
        </ActionButton>
      }
      <div style={{clear: 'both'}}/>
    </Container>
  )
}

SubSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fileName: PropTypes.string,
}

export default SubSection
