import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`

`

const Title = styled.h1`
  font-family: 'Anton', 'sans-serif';
  font-size: 38px;
  font-weight: normal;
  text-align: center;
  border-bottom: 3px #d40000 solid;
  max-width: 500px;
  margin: 15px auto 25px;
`

function Section({ title, children }) {

  return (
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  )
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]).isRequired,
}

export default Section
